import { createContext, useContext } from "react";
import { PUBLIC_ENV } from "~/env.server";

interface RootContextType {
  ENV: typeof PUBLIC_ENV;
}

export const RootContext = createContext<RootContextType | undefined>(undefined);

export const useRootContext = () => {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error("useRootContext must be used within a RootContext provider");
  }
  return context;
};
